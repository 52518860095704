import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JobVacancyTextSearchComponent } from './job-vacancy-text-search.component';
import { TextSearchModule } from 'src/app/shared/input-fields/text-search/text-search.module';



@NgModule({
  declarations: [JobVacancyTextSearchComponent],
  imports: [
    CommonModule,
    TextSearchModule
  ],
  exports: [JobVacancyTextSearchComponent]
})
export class JobVacancyTextSearchModule { }
